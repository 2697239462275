<template>
  <v-container>
    <v-row class="mt-7" align-content="center" justify="center">
      <v-col cols="auto">
        <v-row
          class="mt-7"
          align-content="center"
          justify="center"
          v-if="!openQuotation && !error"
        >
          <v-progress-circular
            :size="50"
            indeterminate
            color="#1976d2"
            v-if="isloading"
          ></v-progress-circular>
        </v-row>
        <p class="global-TextTitleBlack mt-4" v-if="!openQuotation && !error">
          {{ msgLoading }}
        </p>
        <a v-if="this.urlDocumento != ''" :href="urlDocumento">Ver Póliza</a>
        <!-- <p class="global-TextTitleBlack mt-4" v-if="error">{{ errorMsg }}</p> -->
      </v-col>
    </v-row>
    <v-row class="mt-7" style="height: 500px"></v-row>
    <Modal
      @closeDialogTwo="closeQuotation"
      :open="openQuotation"
      :data="data"
      :idIntencion="ids.idIntencion"
      :idTrxForum="ids.idTrxForum"
      ref="modalforum"
      @submit="submitQuot"
    />
    <ModalError
      @closeDialogTwo="closeError"
      :open="openError"
      :error="errorMsg"
      :idIntencion="ids.idIntencion"
      :idTrxForum="ids.idTrxForum"
    />
  </v-container>
</template>
<script>
import axios from "axios";
import Modal from "@/components/Modal/Forum/base.vue";
import ModalError from "@/components/Modal/Forum/error.vue";
import { endpoints, idKeys } from "@/helpers/endpoints.js";

const validator = require("validator");
export default {
  name: "ForumIntegrationComponent",
  components: {
    Modal,
    ModalError,
  },
  data: () => ({
    errorMsg: "",
    error: false,
    isloading: true,
    action_code: 0,
    data: {},
    msgLoading: "Obteniendo información desde Finex",
    ids: {
      idIntencion: 0,
      idTrxForum: 0,
    },
    newqLoading: false,
    openQuotation: false,
    openError: false,
    urlDocumento: "",
    token: process.env.VUE_APP_X_MESOS_KEY,
  }),

  mounted() {
    this.setup();
  },
  methods: {
    closeQuotation() {
      this.openQuotation = !this.openQuotation;
      this.openError = !this.openError;
      this.loading = false;
      this.newqLoading = false;
    },
    closeError() {
      this.openQuotation = !this.openQuotation;
      this.loading = false;
      this.newqLoading = false;
    },

    setup() {
      let { idIntencion, idTrxForum } = this.$route.query;
      this.ids = {
        idIntencion: idIntencion,
        idTrxForum: idTrxForum,
      };
      if (idIntencion && idTrxForum) {
        // CWE-918 JDRA 20241025 Correccion de incidencia
        const url = endpoints.integration.forum
          .replace(idKeys.proposal, idIntencion)
          .replace(idKeys.docId, idTrxForum);
        const safeUrl = this.validateUrl(url);
        this.getData(safeUrl, idIntencion, idTrxForum);
      } else {
        this.errorMsg = "Error en la lectura de parametros";
        this.error = true;
        this.openError = true;
      }
    },
    // CWE-918 JDRA 20241016
    validateUrl(inputUrl) {
      if (
        !validator.isURL(inputUrl, {
          protocols: ["http", "https"],
          require_protocol: true,
        })
      ) {
        throw new Error("Invalid URL");
      }
      return inputUrl;
    },
    async getData(urlPAram) {
      try {
        const response = await axios({
          url: urlPAram,
          method: "GET",
          headers: {
            "x-mesos-key": this.token,
          },
        });
        const result = response.data;

        // Solo ver el documento de poliza
        if (result.codigo_accion === 1) {
          this.msgLoading = `Obteniendo póliza`;
          const { documentos } = result.data;

          if (documentos.length <= 0) {
            this.errorMsg = `No se pudo obtener el documento de póliza`;
            this.error = true;
            this.openError = true;
          }
          const documentoPoliza = documentos.find(
            (doc) => doc.documento === "POL"
          );

          if (!documentoPoliza?.url_archivo) {
            this.errorMsg = `No se pudo obtener el documento de póliza, intente nuevamente en unos minutos más`;
            this.error = true;
            this.openError = true;
            return;
          }
          const urlArchivo = documentoPoliza.url_archivo;
          const documentoBucket = await axios.default({
            method: "get",
            url: urlArchivo,
          });
          const { url: documentoDescargar } = documentoBucket.data.data;
          this.isloading = false;
          this.msgLoading = `Presiona el siguiente link para ver la póliza`;
          this.urlDocumento = documentoDescargar;
          return;
        }

        this.data = result.data;
        const { token, cotizacion_iniciada, cotizacion } = result.data;

        // Si la cotizacion ha sido iniciada, tomar accion:
        if (cotizacion_iniciada) {
          const query = {
            decoded: true,
            token,
          };

          // Agregar parametros de redireccion
          if (cotizacion) {
            if (cotizacion.numero_propuesta) {
              // Enviar a ofertas
              query.proposal_number = cotizacion.numero_propuesta;
              query.page = 5;
            } else if (cotizacion.numero_cotizacion) {
              // Enviar a propuesta
              query.quot_number = cotizacion.numero_cotizacion;
              query.page = 3;
            }
          }

          this.$router.push({
            name: "SignIn",
            query,
          });
        } else {
          // En caso de que no se haya creado una cotizacion, levantar informacion:
          this.$store.commit("REFRESH_TOKEN", token);
          const selectedData = {
            ...this.data.selected,
            vehiculo: result.data?.vehiculo,
          };
          this.openQuotation = true;
          this.$refs.modalforum.listData(selectedData);
        }
      } catch (error) {
        this.error = true;
        this.openError = true;
        if (error.response?.status == 422) {
          this.errorMsg = error.response.data.data.lista_mensaje[0].mensaje;
        } else {
          this.errorMsg =
            "Ha ocurrido un error inesperado, porfavor intente mas tarde.";
        }
      }
    },

    submitQuot(nro = "") {
      this.$router.push({
        name: "SignIn",
        query: {
          token: this.data.token,
          decoded: true,
          quot_number: nro,
          page: 3,
        },
      });
    },
  },
};
</script>
